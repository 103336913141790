<template>
  <v-col
    cols="12"
    md="4"
  >
    <v-card
      elevation="24"
      outlined
      min-height="300px"
    >
      <v-card-text>
        <template v-if="!showEdit">
          <p class="text-h6 text--primary">
            <v-row>
              <v-col
                cols="12"
                class="text-center"
              >
                <v-avatar
                  v-if="proveedorApi.logo === null"
                  color="primary"
                  size="60"
                >
                  <span class="white--text text-h5">{{ proveedorApi.name_comercial[0] }}</span>
                </v-avatar>
                <v-avatar
                  v-else
                  class="profile"
                  size="80"
                  tile
                >
                  <v-img
                    :src="rutaPublic + proveedorApi.logo"
                    :alt="proveedorApi.name_comercial"
                    contain
                  ></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12">
                {{ proveedorApi.name_comercial }}
              </v-col>
            </v-row>
          </p>
          <div class="text--primary">
            <p>
              <b>Usuario</b>: {{ item.user }}<br />
              <b>Contraseña</b>:
              <code v-if="showPassword">
                {{ item.password }}
              </code>
              <template v-else>
                <code>
                  ***************
                </code>
                <v-btn
                  class="ml-4"
                  icon
                  @click="showPassword = !showPassword"
                >
                  <v-icon>{{ icons.mdiEyeOffOutline }}</v-icon>
                </v-btn>
              </template>
            </p>
          </div>
        </template>
        <template v-if="showEdit">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="item.proveedor_id"
                :items="itemsProveedor"
                :search-input.sync="searchProveedor"
                hide-details
                hide-selected
                :label="$t('lbl.proveedor')"
                outlined
                dense
                item-text="name_comercial"
                item-value="id"
                @change="setProveedor"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.proveedor') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name_comercial"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="item.user"
                label="Usuario"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="item.password"
                :label="$t('lbl.password')"
                :type="isPasswordVisible ? 'text' : 'password'"
                outlined
                dense
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-divider></v-divider>
            </v-col>

            <v-col
              cols="12"
              md="5"
            >
              <v-btn
                color="primary"
                outlined
                block
                :loading="loading"
                :disabled="!item.proveedor_id || !item.user || !item.password"
                @click="confirmAdd"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              md="5"
            >
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-tooltip
          v-if="!showEdit"
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="editItem"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.edit') }}</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip
          top
          color="error"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="isDialogVisibleDelete = !isDialogVisibleDelete"
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('btn.delete') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p class="mt-5">
            {{ $t('msg.deleteItem', { n: proveedorApi.name_comercial }) }}
          </p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiClose, mdiEyeOffOutline, mdiEyeOutline, mdiArrowLeft, mdiDeleteOutline, mdiSquareEditOutline,
} from '@mdi/js'

export default {
  props: {
    pos: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    proveedores: {
      type: Array,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isDialogVisibleDelete: false,
      showPassword: false,
      showEdit: false,
      loading: false,
      icons: {
        mdiClose,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiArrowLeft,
        mdiDeleteOutline,
        mdiSquareEditOutline,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      logo: null,
      currencies: [],
      isPasswordVisible: false,
      itemsProveedor: [],
      searchProveedor: null,
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      proveedorApis: state => state.app.proveedorApis,
    }),
    proveedorApi() {
      let result = {
        name_comercial: '',
      }
      if (this.proveedores.length > 0) {
        const r = this.proveedores.filter(e => e.id === this.item.proveedor_id)
        if (r.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          result = r[0]
        }
      }

      return result
    },
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  created() {
    if (this.item.proveedor_id) {
      this.itemsProveedor = this.proveedores.filter(e => e.id === this.item.proveedor_id)
    } else {
      this.showEdit = true
    }
  },
  methods: {
    ...mapMutations(['deleteProveedorApi']),
    editItem() {
      this.itemsProveedor = []
      if (this.item.proveedor_id) {
        this.itemsProveedor = this.proveedores.filter(e => e.id === this.item.proveedor_id)
      }
      this.showEdit = true
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = []
        const proveedoresFree = []
        this.proveedores.forEach(elementProv => {
          let esta = false
          this.api.proveedores.forEach(elementApi => {
            if (elementApi.proveedor_id === elementProv.id) {
              esta = true
            }
          })
          if (!esta) {
            proveedoresFree.push(elementProv)
          }
        })

        if (proveedoresFree.length > 0) {
          this.itemsProveedor = proveedoresFree.filter(e => e.name_comercial.toLowerCase())
        }
      }
    },
    setProveedor() {
      if (this.item.proveedor_id) {
        this.item.proveedor_slug = this.proveedores.filter(e => e.id === this.item.proveedor_id)[0].slug
      } else {
        this.item.proveedor_slug = null
      }
    },

    confirmDelete() {
      const json = {
        api_id: this.api.id,
        proveedor_id: this.item.proveedor_id,
      }

      this.axios
        .post('api_extern/delete-proveedor', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.isDialogVisibleDelete = false
          this.$emit('setApi')
        })
    },
    confirmAdd() {
      const json = {
        api_id: this.api.id,
        proveedores: this.proveedorApis,
      }
      this.loading = true

      this.axios
        .post('api_extern/add-proveedor', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.loading = false
          this.showEdit = false
          this.$emit('setApi')
        })
    },
  },
}
</script>
