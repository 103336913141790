var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"elevation":"24","outlined":"","min-height":"300px"}},[_c('v-card-text',[(!_vm.showEdit)?[_c('p',{staticClass:"text-h6 text--primary"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(_vm.proveedorApi.logo === null)?_c('v-avatar',{attrs:{"color":"primary","size":"60"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.proveedorApi.name_comercial[0]))])]):_c('v-avatar',{staticClass:"profile",attrs:{"size":"80","tile":""}},[_c('v-img',{attrs:{"src":_vm.rutaPublic + _vm.proveedorApi.logo,"alt":_vm.proveedorApi.name_comercial,"contain":""}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.proveedorApi.name_comercial)+" ")])],1)],1),_c('div',{staticClass:"text--primary"},[_c('p',[_c('b',[_vm._v("Usuario")]),_vm._v(": "+_vm._s(_vm.item.user)),_c('br'),_c('b',[_vm._v("Contraseña")]),_vm._v(": "),(_vm.showPassword)?_c('code',[_vm._v(" "+_vm._s(_vm.item.password)+" ")]):[_c('code',[_vm._v(" *************** ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEyeOffOutline))])],1)]],2)])]:_vm._e(),(_vm.showEdit)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsProveedor,"search-input":_vm.searchProveedor,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.proveedor'),"outlined":"","dense":"","item-text":"name_comercial","item-value":"id"},on:{"update:searchInput":function($event){_vm.searchProveedor=$event},"update:search-input":function($event){_vm.searchProveedor=$event},"change":_vm.setProveedor},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.proveedor'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name_comercial)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name_comercial))])],1)]}}],null,false,3152151403),model:{value:(_vm.item.proveedor_id),callback:function ($$v) {_vm.$set(_vm.item, "proveedor_id", $$v)},expression:"item.proveedor_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Usuario","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.item.user),callback:function ($$v) {_vm.$set(_vm.item, "user", $$v)},expression:"item.user"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.password'),"type":_vm.isPasswordVisible ? 'text' : 'password',"outlined":"","dense":"","placeholder":"············","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.item.password),callback:function ($$v) {_vm.$set(_vm.item, "password", $$v)},expression:"item.password"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","block":"","loading":_vm.loading,"disabled":!_vm.item.proveedor_id || !_vm.item.user || !_vm.item.password},on:{"click":_vm.confirmAdd}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"5"}})],1)]:_vm._e()],2),_c('v-card-actions',[(!_vm.showEdit)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.editItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,false,2423837666)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"350px"},model:{value:(_vm.isDialogVisibleDelete),callback:function ($$v) {_vm.isDialogVisibleDelete=$$v},expression:"isDialogVisibleDelete"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('btn.delete')))]),_c('v-divider'),_c('v-card-text',[_c('p',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$t('msg.deleteItem', { n: _vm.proveedorApi.name_comercial }))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }